.header {
  background-color: map-get($light-blue, 600);
  color: #fff;
  display: table-row;
  height: 1px;
  z-index: 100;

  &.header-minimal {
    background-color: #fff;

    &.primary {
      background-color: map-get($light-blue, 600);
    }
  }
}
.main {
  min-height: calc(var(--vh, 1vh) * 100);
  @media (max-width: $screen-sm-max) {
    overflow-y: auto;
  }
}
.nav {
  li {
    &.has-children:hover {
      div {
        visibility: visible;
        width: auto !important;
        height: auto !important;
      }
    }
  }
}
.filter {
  background-color: #0395de;
  padding: 0;
  .nav {
    @media (max-width: $screen-xs-max) {
      overflow: visible;
      height: 58px;
    }
    li {
      display: inline-block;
      &.has-children {
        div {
          @media (max-width: $screen-xs-max) {
            -webkit-backface-visibility: hidden;
            background-color: #fff;
            border-radius: 1px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
            height: 0px;
            left: 0px;
            overflow: hidden;
            position: absolute;
            top: 0px;
            width: 0px;
            z-index: 999;
            a {
              display: block;
              color: #000;
              text-decoration: none;
              .fa {
                color: #000;
              }
            }
          }
        }
      }
      &.has-children:hover {
        div {
          visibility: visible;
          width: auto !important;
          height: auto !important;
          @media (max-width: $screen-xs-max) {
            -webkit-backface-visibility: hidden;
            background-color: #fff;
            border-radius: 1px;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
            height: 0px;
            left: 0px;
            overflow: hidden;
            position: absolute;
            top: 0px;
            width: 0px;
            z-index: 9999;
            a {
              display: block;
              color: #000;
              .fa {
                color: #000;
              }
            }
          }
        }
      }
      &.has-children {
        ul.sub-menu {
          li {
            display: block;
            label.selectall {
              padding: 10px 25px 10px 20px;
              font-weight: bold;
            }
            label {
              background-color: #fff;
              margin: 0;
              padding: 10px 25px 10px 30px;
              display: block;
              font-weight: normal;
              position: relative;
              text-transform: none;
              transition: background-color 0.15s linear;
              white-space: nowrap;
              min-width: 110px;
              font-size: 15px;
              &:hover {
                background-color: #e2e2e247;
              }
            }
            .radio-container {
              display: block;
              position: relative;
              padding: 10px 35px;
              cursor: pointer;
              font-size: 16px;
              margin-bottom: 0;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
              }
              input:checked ~ .checkmark {
                border: solid 2px map-get($color-secondary, 500);
              }
              input:checked ~ .checkmark:after {
                display: block;
              }
              .checkmark:after {
                top: 3px;
                left: 3px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: map-get($color-secondary, 500);
              }
            }

            .checkmark {
              position: absolute;
              top: 10px;
              left: 10px;
              height: 16px;
              width: 16px;
              border: solid 2px #e0e0e0;
              border-radius: 50%;
            }

            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
          }
        }
      }
      a {
        margin: 16px 20px;
        @media (max-width: $screen-xs-max) {
          margin: 0;
          color: #fff;
          font-size: 16px;
          font-weight: normal;
          .fa {
            display: inline-block;
            color: #fff;
            margin: 0 5px;
          }
        }
        &:hover {
          border: 0;
          border-radius: 0;
          color: #000;
          .fa {
            color: #000;
          }
        }
      }
    }
  }
}

.header-inner {
  position: relative;

  .header-standard & {
    padding: 15px 0px;
  }

  .header-minimal & {
    padding: 10px 0px;
  }
}

.header-main {
  @extend .clearfix;

  .header-large & {
    margin: 37px 0px;
  }

  .header-call-to-action-wrapper & {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px 700px 20px 700px;
    margin: -27px -700px 0px -700px;
  }
  .contact-area {
    float: left;
    line-height: 18px;
    margin-top: 12px;
    margin-right: 20px;
    i {
      color: #b7e0fc;
      font-size: 24px;
      float: left;
      margin: -2px 15px 0 0;
    }
    span {
      opacity: 0.7;
    }
  }
  .user-area {
    float: left;
    margin-top: 10px;
    i.fa.fa-user-circle {
      font-size: 30px;
      margin-right: 5px;
    }
    &.hidden-xs {
      a {
        font-size: 20px;
        font-family: "Montserrat";
        font-weight: 600;
      }
    }
    &.visible-xs {
      float: none;
      border-top: solid 1px #ecebeb;
      border-bottom: solid 1px #ecebeb;
      label,
      a {
        color: #000;
        font-size: 16px;
        padding: 15px 0 15px 22px;
        margin: 0;
        width: 100%;
      }
      a {
        width: 100%;
        background: #fff;
        box-shadow: none;
        text-align: left;
        border-bottom: solid 1px #ecebeb;
      }
      i {
        margin-right: 0;
      }
      img {
        width: 30px;
      }
    }
    a,
    label {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      float: left;
      &:hover {
        text-decoration: none;
      }
    }
    a {
      text-transform: initial;
      border: 0;
    }
    label {
      margin-right: 15px;
      position: relative;
      ul.user-options {
        position: absolute;
        right: 0;
        top: 38px;
        background: #fff;
        list-style: none;
        min-width: 250px;
        border-radius: 5px;
        border: solid 1px #ccc;
        z-index: 9999;
        box-shadow: 3px 1px 30px -9px rgba(60, 42, 42, 0.65);
        li {
          padding: 15px;
          margin-bottom: 0;
          border-bottom: solid 1px #cccc;
          font-weight: normal;
          width: 100%;
          &:first-child {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            display: inline-flex;
          }
          &:last-child {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom: 0;
          }
          &:hover {
            background: #f5f3f3;
          }
          i.fa.fa-user-circle {
            font-size: 45px;
            margin-right: 10px;
          }
          span {
            line-height: 30px;
            font-size: 14px;
          }
          img {
            float: left;
            margin-right: 10px;
            width: 50px;
            height: 50px;
            margin-top: 12px;
          }
        }
      }
    }
  }
  .contact-area {
    a {
      color: #fff;
    }
    &.visible-xs {
      height: auto !important;
      padding: 15px 0 15px 22px;
      border-top: solid 1px #ecebeb;
      div {
        display: inline;
        a {
          color: #414141;
          padding: 0;
        }
      }
      i {
        color: #616161;
        font-size: 16px;
        float: left;
        margin: 0px 5px 0 0;
      }
    }
  }
}

.header-title {
  float: left;
  font-family: "Montserrat";
  font-size: 32px;
  margin-top: 15px;

  .header-minimal & {
    font-size: 24px;
    margin-top: 8px;
  }

  img {
    margin-right: 15px;
    opacity: 0.5;
    transition: opacity 0.15s linear;
    vertical-align: -8px;

    .header-minimal & {
      height: 34px;
      opacity: 1;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin-right: 0px;
    }
  }

  a {
    color: #fff;

    .header-minimal & {
      color: map-get($grey, 800);
    }

    .header-minimal.primary & {
      color: #fff;
    }

    &:hover {
      color: #fff;
      text-decoration: none;

      .header-minimal & {
        color: map-get($grey, 800);
      }

      .header-minimal.primary & {
        color: #fff;
      }
    }

    &:active,
    &:focus {
      text-decoration: none;
    }
  }

  span {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    }
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
}

.header-title-image {
  fill: map-get($color-primary, 200);
  height: 36px;
  margin-right: 10px;
  vertical-align: -6px;
  width: 36px;
}

.header-search {
  float: right;
  margin-left: 30px;
  width: 50%;
  margin-top: 10px;
  border: solid 1px #cccccc54;
  background: #fff;
  border-radius: 20px;
  button.close {
    display: none;
  }
  @media (max-width: $screen-sm-max) {
    margin-left: 10px;
  }
  @media (max-width: $screen-xs-max) {
    // margin: 0px;
    // position: absolute;
    // z-index: 1000;
    // right: -127%;
    // top: 10px;
    // box-shadow: 1px 1px 4px #00000078;
    // border: solid 1px #ccc;
    // background: #fff;
    // border-radius: 20px;
    // transform: none;
    display: none;
    .input-group-btn .btn {
      right: 10px;
      width: 20px;
    }
    .input-group-btn .btn i {
      margin-right: 32px !important;
      font-size: 19px !important;
    }
    input.form-control {
      height: 29px !important;
      margin: 11rem 3rem 10.2rem 1rem;
      border-bottom: solid 4px #0395de !important;
      font-size: 20px;
      font-weight: 500;
      // padding-top: 35px;
      &::placeholder {
        opacity: 0.5;
      }
    }
    button.close {
      display: block;
      width: 25px;
      height: 25px;
      margin-top: -4px;
      top: 10px;
      font-size: 30px;
      right: 10px;
      border-radius: 50%;
      color: red;
      position: absolute;
      opacity: 0.3;
      font-weight: bold;
      &:focus {
        outline: none;
      }
    }
    &.opened {
      // right: 75px !important;
      // transform: none !important;
      // width: 90%;
      // left: 20px !important;
      position: fixed;
      top: 0px;
      left: 0px;
      background: hsla(0, 0%, 95%, 0.87);
      height: calc(100vh);
      width: 100%;
      z-index: 9999;
      border-radius: 0;
      float: none !important;
      margin: 0;
      display: block;
    }
  }

  .input-group {
    margin: 0px;
    padding: 0 15px;
    width: 100%;
  }

  .form-control {
    background-color: transparent;
    box-shadow: none;
    border: 0px !important;
    border-radius: 0px;
    color: #000;
    font-size: 16px;
    height: 34px;
    padding: 0px;
    transition: border-color 0.15s linear;

    @include input-placeholder {
      color: #000;
      opacity: 0.8;
    }
  }

  .input-group-btn {
    .btn {
      background-color: transparent;
      border: 0px;
      border-radius: 0px;
      box-shadow: none;
      height: 34px;
      transition: border-color 0.15s linear;
      padding: 0;
      color: #000;

      i {
        font-size: 14px;
        vertical-align: middle;
        margin-right: 0;
      }

      &:hover,
      &:focus {
        color: #000;
        outline: 0px !important;
      }

      &:after,
      &:before {
        display: none;
      }
    }
  }
  &.visible-xs {
    background: transparent;
    box-shadow: none;
    border: 0;
    top: 11px;
    transform: none !important;
    z-index: 999;
    right: 75px;
    left: auto;
    position: absolute;
    width: auto;
    .input-group-btn {
      text-align: right;
      .btn {
        border-bottom: 0;
        color: #fff;
        width: 34px;
        i {
          font-size: 22px;
        }
      }
    }
    .input-group {
      margin: 0px;
      position: absolute;
      z-index: 9999;
      left: 50%;
      transform: translateX(-50%);
      width: 65%;
      top: 145px;
      box-shadow: 1px 1px 4px #00000078;
      border: solid 1px #ccc;
      background: #fff;
      border-radius: 20px;
      input {
        border-bottom: 0;
        color: #000;
        &::placeholder {
          color: #000;
        }
      }
    }
  }
}

.header-action {
  background-color: map-get($color-secondary, 500);
  border-radius: 50%;
  bottom: -85px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  display: block;
  height: 56px;
  line-height: 54px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  text-align: center;
  transition: box-shadow 0.15s linear;
  width: 56px;
  z-index: 8888 !important;

  .fa {
    font-size: 18px;
    line-height: 58px;
  }

  &:hover {
    color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
  }

  &:active,
  &:focus {
    color: #fff;
    outline: 0px;
  }

  .header-large & {
    bottom: -64px;
    right: 5px;
  }
}

.header-call-to-action {
  margin: 84px 0px;
  text-align: center;

  h1 {
    font-size: 40px;
    margin: 0px 0px 30px 0px;
    padding: 0px;
  }

  h2 {
    font-size: 14px;
    line-height: 24px;
    margin: 0px auto;
    overflow: hidden;
    padding: 0px;
    width: 50%;
  }

  .buttons {
    margin-top: 60px;

    a {
      margin: 0px 15px;
    }

    @media (max-width: $screen-xs-max) {
      .btn {
        display: block;
        float: none;
        margin: 0px 0px 10px 0px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.header-background {
  background-image: url("../../img/tmp/mix-bw.png");
  background-size: 1083px 536px;
}

.header-background-inner {
  background-color: rgba(map-get($color-primary, 500), 0.85);
  position: relative;
}

@media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
  .header-main {
    & > .pull-right {
      position: absolute;
      right: 0;
    }
  }
}
