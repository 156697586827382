.main {
    // margin-bottom: 60px;
}

.content, .sidebar {
    margin-bottom: 60px;
    margin-top: 60px;
    @media (max-width: $screen-xs-max) {
        margin-bottom: 25px;
        margin-top: 25px;
    }
}
