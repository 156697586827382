.promotion {
    @extend .clearfix;

    background-color: map-get($grey, 200);
    margin: 0px 0px 30px 0px;
    padding: 32px;
}

.promotion-icon {
    color: map-get($color-secondary, 400);
    float: left;
    margin: 0px 30px 0px 0px;

    .fa {
        float: left;
        font-size: 70px;
    }
}

.promotion-text {
    color: map-get($grey, 800);
    display: table-cell;
    font-size: 18px;
    line-height: 28px;

    a {
        border-bottom: 1px solid map-get($color-secondary, 400);
        color: map-get($color-secondary, 400);

        &:hover {
            text-decoration: none;
        }
    }
}
