.help {
  color: #a6a6a6;
  font-size: 11px;
}

.center {
  margin: auto;
  width: 90%;

  padding: 10px;
}

.deleteBtn {
  float: right;
  cursor: pointer;
  font-size: 20px !important;
}
.deleteBtn:hover {
  color: crimson;
}

.dropzone,
.dropzone *,
.dropzone-previews,
.dropzone-previews * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* 
.focus:hover {
  border: 2px solid rgb(233, 30, 99);
} */

.dropzone {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.02);
  padding: 1em;
}

.dropzone:hover {
  border-color: rgba(0, 0, 0, 0.15);
  background: #e6e6e6;
}

.dropzone .dz-preview,
.dropzone-previews .dz-preview {
  background: #e6e6e6;
  position: relative;
  display: inline-block;
  margin: 0 10px 10px;
  vertical-align: top;
  padding: 5px;
  border: 1px solid #d6d5d5;
  border-radius: 10px;
  background: #fff;
}
.dropzone {
  border: 1px solid rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #e6e6e6;
  padding: 20px;
  margin-bottom: 20px;
}
.dropzone p {
  margin-bottom: 0;
  font-size: 15px;
  text-align: center;
}
