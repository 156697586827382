.property-chart {
    margin: 0px;
}

#property-chart {
    height: 300px;

    .nvd3 .nv-indexLine {
        display: none;
    }

    .nvd3 .nv-axis line {
        stroke: rgba(black, .05);
    }

    .nvd3 .nv-axis path.domain {
        display: none;
    }

    .nv-controlsWrap {
        display: none;
    }

    .nvd3 .nv-groups path.nv-line {
        stroke: map-get($color-secondary, 500);
    }

    .tick text {
        fill: map-get($grey, 700);
    }

    .nvd3 .nv-axis .nv-axisMaxMin text, .nvd3 .nv-axis .nv-axisMaxMin text {
        fill: map-get($grey, 700);
    }

    .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
        stroke: map-get($color-primary, 500);
    }
}
