.map-wrapper {
  position: relative;
}

.map {
  background-color: #fff;
  height: calc(100vh - 138px);
  position: relative;

  .load-bar {
    position: absolute !important;
  }
}

.marker {
  background-color: map-get($color-primary, 500);
  border-radius: 50%;
  border: 2px solid #fff;
  height: 40px;
  line-height: 32px;
  text-align: center;
  width: 40px;

  img {
    vertical-align: middle;
    width: 16px;
  }

  &.pink {
    background-color: map-get($pink, 500);
  }

  &.cyan {
    background-color: map-get($cyan, 500);
  }

  &.blue {
    background-color: map-get($blue, 500);
  }

  &.deep-purple {
    background-color: map-get($deep-purple, 500);
  }

  &.indigo {
    background-color: map-get($indigo, 500);
  }

  &.teal {
    background-color: map-get($teal, 500);
  }

  &.green {
    background-color: map-get($green, 500);
  }

  &.light-green {
    background-color: map-get($light-green, 500);
  }

  &.lime {
    background-color: map-get($lime, 500);
  }

  &.yellow {
    background-color: map-get($yellow, 700);
  }

  &.amber {
    background-color: map-get($amber, 500);
  }

  &.orange {
    background-color: map-get($orange, 500);
  }

  &.deep-orange {
    background-color: map-get($deep-orange, 500);
  }

  &.brown {
    background-color: map-get($brown, 500);
  }
}

.clusterer {
  background-color: map-get($color-primary, 400);
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 600;
  height: 40px;
  line-height: 38px;
  text-align: center;
  width: 40px;
}

.map-filter-horizontal {
  background-color: rgba(#fff, 0.92);
  border-bottom: 2px solid map-get($grey, 200);
  border-top: 1px solid rgba(black, 0.05);
  bottom: 0px;
  left: 0px;
  padding: 41px 0px;
  position: absolute;
  right: 0px;

  .form-group {
    margin-bottom: 0px;
  }

  .form-control,
  .bootstrap-select button.btn {
    box-shadow: 0px 1px 0px map-get($grey, 400);
    color: map-get($grey, 600);

    @include input-placeholder {
      color: map-get($grey, 600);
    }

    &:focus {
      box-shadow: 0px 2px 0px map-get($color-primary, 400);
    }
  }

  .bootstrap-select div.dropdown-menu li.selected a {
    background-color: map-get($color-primary, 600) !important;
  }

  .bootstrap-select.open button.btn {
    box-shadow: 0px 2px 0px map-get($color-primary, 400) !important;
    color: map-get($grey, 600) !important;
  }
}

.infobox {
  @extend .clearfix;
  background-color: transparent;
  height: 120px;
  position: relative;
  width: 420px;

  .close {
    color: map-get($grey, 800);
    cursor: pointer;
    height: 17px;
    display: block;
    font-size: 10px;
    line-height: 17px;
    opacity: 1;
    position: absolute;
    right: 10px;
    text-align: center;
    text-indent: 0px;
    top: 10px;
    transition: background-color 0.15s linear, color 0.15s linear;
    width: 17px;
  }

  &:before {
    border: 6px solid transparent;
    border-right-color: map-get($color-primary, 500);
    content: "";
    display: block;
    height: 0px;
    left: -12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0px;
    z-index: 999;
  }

  &.pink:before {
    border-right-color: map-get($pink, 500);
  }

  &.cyan:before {
    border-right-color: map-get($cyan, 500);
  }

  &.blue:before {
    border-right-color: map-get($blue, 500);
  }

  &.deep-purple:before {
    border-right-color: map-get($deep-purple, 500);
  }

  &.indigo:before {
    border-right-color: map-get($indigo, 500);
  }

  &.teal:before {
    border-right-color: map-get($teal, 500);
  }

  &.green:before {
    border-right-color: map-get($green, 500);
  }

  &.light-green:before {
    border-right-color: map-get($light-green, 500);
  }

  &.lime:before {
    border-right-color: map-get($lime, 500);
  }

  &.yellow:before {
    border-right-color: map-get($yellow, 700);
  }

  &.orange:before {
    border-right-color: map-get($orange, 500);
  }

  &.deep-orange:before {
    border-right-color: map-get($deep-orange, 500);
  }

  &.indigo:before {
    border-right-color: map-get($indigo, 500);
  }

  &.amber:before {
    border-right-color: map-get($amber, 500);
  }

  &.brown:before {
    border-right-color: map-get($brown, 500);
  }

  &:hover {
    .infobox-contact {
      margin-left: 0px;
    }
  }
}

.infobox-image {
  @include image_hover;
  background-color: rgba(black, 0.12);
  border-radius: 2px 0px 0px 2px;
  border-left: 4px solid map-get($color-primary, 500);
  display: block;
  float: left;
  height: 120px;
  margin: 0px;
  position: relative;
  width: 120px;

  &:before {
    @include placeholder("\f015", 24);
  }

  .orange & {
    border-color: map-get($orange, 500);
  }

  .deep-orange & {
    border-color: map-get($deep-orange, 500);
  }

  .brown & {
    border-color: map-get($brown, 500);
  }

  .pink & {
    border-color: map-get($pink, 500);
  }

  .cyan & {
    border-color: map-get($cyan, 500);
  }

  .blue & {
    border-color: map-get($blue, 500);
  }

  .deep-purple & {
    border-color: map-get($deep-purple, 500);
  }

  .indigo & {
    border-color: map-get($indigo, 500);
  }

  .teal & {
    border-color: map-get($teal, 500);
  }

  .green & {
    border-color: map-get($green, 500);
  }

  .light-green & {
    border-color: map-get($light-green, 500);
  }

  .lime & {
    border-color: map-get($lime, 500);
  }

  .yellow & {
    border-color: map-get($yellow, 700);
  }

  .amber & {
    border-color: map-get($amber, 500);
  }

  .orange & {
    border-color: map-get($orange, 500);
  }

  .deep-orange & {
    border-color: map-get($deep-orange, 500);
  }

  .brown & {
    border-color: map-get($brown, 500);
  }

  img {
    display: block;
    position: relative;
    z-index: 2;
  }
}

.infobox-content {
  background-color: #fff;
  height: 120px;
  float: left;
  padding: 8px;
  position: relative;
  width: 160px;
  z-index: 2;
}

.infobox-content-title {
  color: map-get($grey, 800);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;

  a {
    color: map-get($grey, 800);

    &:hover {
      text-decoration: none;
    }
  }
}

.infobox-content-body {
  color: map-get($grey, 700);
  font-size: 11px;
  line-height: 18px;
  margin: 0px;
}

.infobox-content-price {
  color: map-get($color-secondary, 400);
  font-size: 14px;
  font-weight: 600;
  margin: -5px 0px 2px 0px;
}

.infobox-contact {
  background-color: map-get($grey, 200);
  height: 120px;
  float: left;
  padding: 8px;
  position: relative;
  margin-left: -140px;
  transition: margin 0.2s linear;
  width: 140px;
  z-index: 1;
}

.infobox-contact-title {
  color: map-get($grey, 800);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;

  a {
    color: map-get($grey, 800);

    &:hover {
      text-decoration: none;
    }
  }
}

.infobox-contact-body {
  color: map-get($grey, 700);

  .fa {
    color: map-get($grey, 900);
    margin: 0px 5px 0px 0px;
    vertical-align: middle;
  }
}

#map-admin {
  @media (max-width: $screen-xs-max) {
    height: 400px !important;
  }
}
.upload-files-container.opened {
  left: 0;
  overflow: auto;
  box-shadow: -12px 21px 70px 12px rgba(0, 0, 0, 0.75);
}
.upload-files-container {
  z-index: 1000;
  position: absolute;
  height: calc(100vh - 145px);
  background-color: #fff;
  top: 56px;
  transition: all 0.4s ease;
  left: -100%;
  padding-bottom: 2rem;
  @media (max-width: $screen-xs-max) {
    height: calc(100vh - 138px);
    top: 58px;
  }
  .selectnoborder {
    input {
      border: 0px !important;
    }
    .css-1g6gooi {
      margin: 0px !important;
      padding: 0px !important;
    }
  }
  ._loading_overlay_wrapper {
    display: inline;
  }
  .browserBtn {
    float: right;
  }
  h2 {
    color: #4a4848;
    margin: 20px 0;
  }
  button.close {
    font-size: 36px;
  }
  h3 {
    color: #807c7c;
    margin-top: 30px;
  }
  input {
    border: solid 1px hsl(0, 0%, 80%) !important;
    border-radius: 4px;
    height: 38px;
    padding: 2px 8px;
    font-size: 13px;
    box-shadow: none;
  }
  .file-upload {
    height: 33px;
    border-radius: 3px;
    input[type="file"] {
      display: none;
    }
    label.custom-file-label {
      background-color: #e91e63;
      border-radius: 2px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      color: #fff;
      font-size: 13px;
      overflow: hidden;
      position: relative;
      text-transform: uppercase;
      transition: all 0.15s linear;
      padding: 10px;
      float: right;
    }
  }
}
.leaflet-control-container {
  .leaflet-top.leaflet-right {
    left: 10px;
    right: auto;
    .leaflet-control {
      margin-top: 65px;

      &.leaflet-control-layers-expanded {
        padding: 10px 10px 20px 10px;
        label {
          margin-bottom: 10px;
          span {
            // margin-left: 21px;
            position: relative;
            top: 7px;
            margin-left: 20px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .leaflet-control-zoom {
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}
@media (max-width: $screen-xs-max) {
  .custom-control-legend {
    bottom: 75px !important;
  }
}
.use-clusters {
  display: inline-flex;
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 999;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 5px;
  background-clip: padding-box;
  padding: 10px;
  label {
    margin-top: 4px;
    font-size: 14px;
  }
}
.leaflet-control-layers-base {
  input {
    -webkit-appearance: radio;
  }
}
.loader {
  display: none;
  width: 100%;
  position: absolute;
  top: 80px;
  z-index: 999;
  &.visible {
    display: block;
  }
  .loader-content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    .loader-img {
      border: 7px solid #fff;
      border-radius: 50%;
      border-top: 7px solid #e91e63;
      width: 40px;
      margin: auto;
      height: 40px;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
    label {
      display: block;
      color: #e91e63;
      margin-top: 10px;
      text-align: center;
      font-size: 19px;
      font-weight: bold;
    }
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.leaflet-control-layers-base input {
  -webkit-appearance: none;
  position: relative;
  background: #fff;
  float: left;
  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    height: 17px;
    width: 17px;
    border: solid 2px #e0e0e0;
    border-radius: 50%;
  }
  &[type="radio"]:checked::before {
    content: "";
    background: url(../../img/radio-selected.png) #fff;
    height: 17px;
    width: 17px;
    border: none;
    display: inline-block;
  }
  &[type="radio"]:focus {
    outline: none !important;
  }
}
