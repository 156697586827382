.property-amenities {
    margin: 0px 0px 30px 0px;

    ul {
        @extend .row;

        list-style: none;
        margin-bottom: 0px;
        padding: 0px;
    }

    li {
        @extend .col-sm-3;
        color: map-get($grey, 800);
        line-height: 34px;

        &.yes, &.no {
            &:before {
                color: map-get($grey, 400);
                content: '\f00d';
                font-family: 'FontAwesome';
                font-size: 12px;
                margin: 0px 10px 0px 0px;
                vertical-align: top;
            }
        }

        &.yes {
            &:before {
                color: map-get($color-secondary, 400);
            }
        }
    }
}
