.isotope-filter {
    list-style: none;
    margin: 0px 0px 30px 0px;
    padding: 0px;
    text-align: center;

    li {
        display: inline-block;
        margin: 0px 10px;

        &.selected {
            a {
                background-color: #fff;
                border: 1px solid map-get($grey, 200);
                color: map-get($color-secondary, 400);
            }
        }

        @media (max-width: $screen-xs-max) {
            display: block;
            float: none;
            margin: 0px 0px 15px 0px;
            width: 100%;

            &:last-child {
                margin-bottom: 0px;
            }

            a {
                display: block;
            }
        }
    }

    a {
        border: 1px solid transparent;
        color: map-get($grey, 700);
        font-size: 12px;
        font-weight: 600;
        padding: 8px 20px;
        text-decoration: none !important;
        text-transform: uppercase;
        transition: all .15s linear;

        &:hover {
            text-decoration: none;
        }
    }
}

.properties-isotope {
    margin-left: -15px;
    margin-right: -15px;
}
