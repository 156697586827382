.faq-item {
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .08);
    margin: 0px 0px 30px 60px;
    padding: 16px;
    position: relative;

    &:before {
        background-color: map-get($color-secondary, 400);
        color: #fff;
        content: 'Q';
        display: block;
        font-size: 20px;
        font-weight: 600;
        height: 44px;
        left: -60px;
        line-height: 42px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 44px;
    }

    &:after {
        border: 5px solid transparent;
        border-bottom-color: map-get($color-secondary, 400);;
        content: '';
        display: block;
        height: 0px;
        left: -21px;
        position: absolute;
        top: -5px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 0px;
    }
}

.faq-item-question {
    margin: 0px 0px 20px 0px;

    h2 {
        color: map-get($grey, 800);
        margin: 0px;
        padding: 0px;
    }
}

.faq-item-answer {
    color: map-get($grey, 700);
}

.faq-item-meta {
    border-top: 1px solid map-get($grey, 100);
    color: map-get($grey, 600);
    margin-top: 6px;
    padding-top: 16px;
    text-align: right;

    a {
        color: map-get($color-secondary, 400);
    }

    .rate {
        margin-left: 20px;
    }

    .separator {
        margin: 0px 10px;
    }
}
