.property-valuation {
    margin: 0px 0px 30px 0px;
}


.property-valuation-keys, .property-valuation-values {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.property-valuation-keys {
    li {
        color: map-get($grey, 500);
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 28px;
        text-transform: uppercase;
    }
}

.property-valuation-values {
    li {
        @extend .clearfix;
        display: block;
        padding: 5px 0px;

        span {
            background-color: map-get($color-secondary, 400);
            display: block;
            float: right;
            height: 18px;
        }
    }
}
