.property-gallery {
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .08);
    margin: 0px 0px 30px 0px;
    padding: 15px;
}

.property-gallery {
    position: relative;

    .owl-prev, .owl-next {
        color: map-get($color-secondary, 400);
        font-size: 18px;
        position: absolute;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        top: 50%;
    }

    .owl-prev {
        left: -25px
    }

    .owl-next {
        right: -25px;
    }

    @media (max-width: $screen-xs-max) {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 362px !important;
    }
}

.property-gallery-preview {
    a {
        background-color: rgba(black, .12);
        display: block;
        margin: -16px -16px 0px -16px;
        min-height: 200px;
        position: relative;

        &:before {
            @include placeholder('\f015', 60);
            transform: translateX(-50%), translateY(-50%);
        }

        img {
            display: block;
            position: relative;
            width: 100%;
            z-index: 2;
        }
    }
}

.property-gallery-list-wrapper {
    padding: 30px 60px 14px 60px;
}

.property-gallery-list-item {
    float: left;
    list-style: none;
    padding: 0px 2px;
    position: relative;

    a {
        background-color: rgba(black, .12);
        display: block;
        height: 50px;
        width: 50px;

        img {
            display: block;
            height: 50px;
            position: relative;
            width: 50px;
            z-index: 2;
        }

        &:before {
            @include placeholder('\f015', 20);
        }
    }
}
