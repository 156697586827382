.property-badge {
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .08);
    color: map-get($grey, 600);
    display: block;
    font-size: 11px;
    font-weight: 600;
    left: 16px;
    line-height: 1;
    padding: 7px 12px;
    position: absolute;
    text-transform: uppercase;
    top: 16px;
}
