/* td > hr {
  border-top: 3px dotted rgba(0, 0, 0, 1);
  width: 70px;
} */
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  width: 50%;
}

.line-styles ul {
  position: absolute;
  top: 5px;
  width: 126px;
  border: solid 1px #ccc;
  box-sizing: content-box;
  border-radius: 5px;
  background: #fff;
  right: 25%;
}
.line-styles:before {
  content: "";
  position: absolute;
  top: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ccc;
}
.line-styles {
  position: relative;
}
.line-border.line-border-solid {
  border-top: solid 2px #000;
}
.line-border.line-border-dashed {
  border-top: dashed 2px #000;
}
.line-border.line-border-dotted {
  border-top: dotted 2px #000;
}
.line-border {
  width: 35%;
  text-align: center;
  margin: 10px auto;
}
.line-styles ul li {
  list-style: none;
  border-bottom: solid 1px #ccc;
  background: #fff;
  margin-bottom: 0;
}
