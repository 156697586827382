@media (min-width: 768px) {
  .header-navigation .nav li.has-children div > a {
    color: #90caf9;
    transition: color 0.2s linear;
    white-space: nowrap;
    border-bottom: 0;
  }

  .header-navigation .nav li.has-children a {
    text-decoration: none !important;
  }
  .header-navigation .nav li.has-childern:hover > div > a {
    color: #212121;
  }
}
