.property-small {
    border-bottom: 1px solid rgba(black, .12);
    margin: 0px 0px 15px 0px;
    padding: 0px 0px 15px 0px;

    .footer & {
        border-bottom-color: rgba(#fff, .12);
    }

    &:last-child {
        border: 0px;
        margin: 0px;
        padding: 0px;
    }
}

.property-small-inner {
    display: table;
    width: 100%;
}

.property-small-image {
    display: table-cell;
    width: 96px;

    img {
        display: block;
        position: relative;
        width: 100%;
        z-index: 2;
    }
}

.property-small-image-inner {
    @include image_hover;
    background-color: rgba(black, .12);
    display: block;
    height: 60px;
    margin: 0px 16px 0px 0px;
    position: relative;
    width: 80px;

    &:before {
        @include placeholder('\f015', 24);
    }

    &:after {
        background-size: 22px 22px;
    }
}

.property-small-content {
    display: table-cell;
    vertical-align: top;
}

.property-small-title {
    margin: 0px;

    a {
        color: rgba(black, .7);
        font-size: 15px;
        font-weight: 500;

        .footer & {
            color: #fff;
        }
    }

}

.property-small-location {
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
        display: inline;

        a {
            color: map-get($grey, 500);
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;

            .footer & {
                color: rgba(#fff, .6);
            }
        }

        .footer & {
            color: rgba(#fff, .6);
        }
    }
}

.property-small-price {
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-weight: 600;
}
