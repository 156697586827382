.map {
  width: 100%;
  /* height:100vh; */
  height: calc(100vh - 138px);
}

canvas.leaflet-marker-icon {
  cursor: -webkit-grab;
}

.upload-files-container,
.header-action {
  z-index: 1000;
}

._loading_overlay_overlay {
  z-index: 10000 !important;
}

.mm-popup__box {
  top: 255px !important;
}

.mm-popup__btn--success {
  background-color: #e91e63;
  border-color: #e91e63;
  color: #fff;
}

.mm-popup__btn--danger {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #fff;
}

div.main-legend {
  right: 0;
  background: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Roboto", "Arial", sans-serif;
  margin-right: 10px;
}
.main-legend ul:after {
  content: "";
  position: absolute;
  bottom: -7px;
  right: 6px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}
div.main-legend ul {
  list-style-type: none;
  padding: 10px;
  margin: 0;
}

div.main-legend ul li {
  margin-bottom: 0px;
}

div.main-legend .color {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

div.main-legend .crop {
  padding-left: 5px;
  vertical-align: text-bottom;
}

.custom-control-legend {
  display: inline-flex;
  position: absolute;
  bottom: 150px;
  right: 10px;
  z-index: 999;
  background: #fff;
  border-radius: 5px;
  background-clip: padding-box;
}

.leaflet-legend a {
  background-image: url(./legend.svg);
}
.map .leaflet-control-container .leaflet-top.leaflet-right .leaflet-control.leaflet-control-layers-expanded label > span {
 padding: 0;
 margin: 0;
 top: 0;
}

.map .leaflet-control-layers label {
  font-size: 13px;
}
/* .main {
  height: calc(var(--vh, 1vh) * 100);
} */


.loginwelcome {
  width:fit-content;
  margin:auto;
  background-color: #404959;
  padding:.5em;
  height:max-content;
  margin-top: 15px;
  margin-bottom: 15px;
}

.loginwelcomeparagraph {
  text-align:center;
  color:white;
  font-size:18px !important;
  height:100%;
  margin:0;
  font-family:montserrat,Arial,Helvetica,sans-serif;
}

.loginwelcomeparagraphanchor, .loginwelcomeparagraphanchor:hover, .loginwelcomeparagraphanchor:focus {
  color:#00a1f1;
  text-decoration: underline;
  font-weight: bold
}

.logindiv{
  height: 100%;
}

#okta-sign-in{
  margin-top: 15px !important;
}

.field_popup div a{
  color:#0395DE;
  font-weight: bolder;
  margin-bottom: 10px;
}
.marker-popup{
  top:-135px;
  height: 0px;
}

.btn-popup {
  background-color: #0395DE;
  color: white;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  padding: 5px 7px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 10px;
}

.p-popup{
  margin: 5px 0 !important;
}
