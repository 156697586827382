.table:not(.table-simple) thead {
  th {
    background-color: map-get($color-primary, 400);
    border-color: transparent !important;
    color: #fff;
  }
}

.table {
  margin-bottom: 0px !important;
  .rdt_TableHead{
      height: 36px;
    .rdt_TableHeadRow{
      & > div{
        background-color: #42A5F5;
        border-color: transparent !important;
        color: #fff;
        height: 36px;
      }
    }
  }
  .rdt_TableRow{
    min-height: 45px;
    .rdt_TableCell{
      border-color: #ddd;
      background-color: #FAFAFA;
      border-right: 1px solid #ddd;
      height: 45px;
    }
    &:nth-child(odd){
      .rdt_TableCell{
        background-color: #F5F5F5
      }
    }
  }
}

.table-striped > tbody > tr:nth-child(odd) {
  background-color: map-get($grey, 100);
}

.table-simple {
  background-color: #fff;
  border: 1px solid map-get($grey, 200);
  padding: 16px;

  thead {
    th {
      border-bottom: 2px solid map-get($grey, 200) !important;
    }
  }

  tbody {
    color: map-get($grey, 700);

    td {
      border-top: 1px solid map-get($grey, 200) !important;
      padding: 9px 12px;
      vertical-align: middle !important;
    }
  }
}
