input, textarea, button {
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    resize: none;
}

label {
    color: map-get($grey, 700);
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
}

.input-group {
    margin-bottom: 15px;

    .form-control {
        padding-left: 15px;
    }
}

select.form-control{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../img/select-arrow.png) no-repeat #fff right 10px center;
    optgroup{
        font-size: 16px
    }
    option{
      font-size: 14px
    }
    
}

.input-group-addon {
    background-color: map-get($grey, 50);
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 1px 0px 0px rgba(black, .12);
    color: map-get($color-secondary, 600);
    min-width: 48px;
    text-align: center;
    transition: box-shadow .12s linear;

    &.active {
        box-shadow: 0px 2px 0px 0px map-get($color-primary, 600);
    }
}

.form-control {
    border: solid 1px #cccccc !important;
    border-radius: 4px;
    height: 38px;
    padding: 2px 8px;
    font-size: 13px;
    box-shadow: none;
    background-color: #fff;
    color: rgba(black, .7);
    position: relative;
    transition: box-shadow .12s linear;

    @include input-placeholder {
        color: rgba(black, .4);
    }

    &:focus, &:active {
        border: solid 2px #2684ff!important;
        box-shadow: none;
        @include input-placeholder {
            color: map-get($grey, 700);
        }
    }

}

textarea.form-control {
    background-color: transparent;
    border-radius: 0px;
    border: 0px;
    box-shadow: 0px 1px 0px 0px rgba(black, .12);
    color: rgba(black, .7);
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    padding: 10px 0px;
    position: relative;
    transition: box-shadow .15s linear;

    &:focus, &:active {
        box-shadow: 0px 2px 0px 0px map-get($color-primary, 600);
    }
}

input[type=checkbox] {
    border: 0px;
    cursor: pointer;
    height: 20px;
    margin: 0px 10px 0px 0px;
    position: relative;
    overflow: hidden;
    vertical-align: -5px;
    width: 20px;
    background-color: #fff;

    &:before {
        background-color: #fff;
        border: 2px solid map-get($grey, 300);
        content: '';
        display: block;
        height: 20px;
        left: 0px;
        opacity: 1;
        position: absolute;
        transition: opacity .15s linear;
        top: 0px;
        width: 20px;
    }

    &:after {
        background-color: #fff;
        border: 2px solid map-get($color-secondary, 400);
        content: '';
        display: block;
        height: 23px;
        left: -5px;
        opacity: 0;
        position: absolute;
        top: -9px;
        transform: rotate(-45deg);
        transition: opacity .15s linear;
        width: 27px;
    }

    &:checked {
        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }

    &:focus {
        box-shadow: none;
        outline: 0px;
    }
}

.bootstrap-select {
    display: block;
    width: 100% !important;

    button.btn {
        background-color: transparent;
        border: 0px;
        border-radius: 0px;
        box-shadow: 0px 1px 0px 0px rgba(black, .12);
        color: rgba(black, .4);
        font-size: 16px;
        height: 48px;
        padding: 0px;
        text-transform: none;

        &:hover {
            background-color: transparent;
            color: rgba(black, .4);
        }

        &:active, &:focus {
            background-color: transparent;
            box-shadow: 0px 2px 0px 0px map-get($color-primary, 600);
            color: rgba(black, .7);
            outline: 0 !important;
        }
    }

    div.dropdown-menu {
        background-color: #fff;
        border: 0px;
        border-radius: 1px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
        display: block;
        height: 0px;
        margin: 2px 0px 0px 0px;
        min-width: inherit !important;
        overflow: hidden;
        padding: 0px;
        right: 0px;
        visibility: hidden;
        width: 0px;

        li {
            margin: 0px;

            &.selected {
                a {
                    background-color: map-get($color-primary, 300) !important;
                    color: #fff;

                    &:before {
                        display: none;
                    }
                }
            }

            a {
                color: #000;
                outline: 0px !important;
                padding-bottom: 10px;
                padding-top: 10px;

                &:before {
                    background-color: map-get($color-black, 100);
                    content: '';
                    display: block;
                    height: 100%;
                    left: 0px;
                    position: absolute;
                    top: 0px;
                    transition: width .15s linear;
                    width: 0px;
                    z-index: 0;
                }

                span {
                    position: relative;
                    z-index: 1;
                }

                &:hover {
                    background-color: transparent;

                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.bootstrap-select.open {
    button.btn {
        background-color: transparent !important;
        box-shadow: 0px 2px 0px 0px map-get($color-primary, 600) !important;
        color: rgba(black, .7) !important;
    }

    div.dropdown-menu {
        visibility: visible;
    }
}

.file-input {
    .form-control {
        height: 37px;
        line-height: 37px;
    }

    .file-caption-name {
        font-size: 13px;
        line-height: 14px;

        .kv-caption-icon {
            color: map-get($color-primary, 400);
            margin-top: -2px;
        }
    }

    @media (max-width: $screen-xs-max) {
        .btn {
            font-size: 12px;
        }
        
        .form-control {
            float: none;
            margin: 0px 0px 10px 0px !important;
        }

        .input-group {
            display: block;
        }

        .input-group-btn {
            display: block;
        }
    }
}

.file-preview {
    border-color: map-get($grey, 50);
    border-radius: 0px;
}

.file-preview-image {
    @media (max-width: $screen-xs-max) {
        height: auto;
        width: 100%;
    }
}

.file-preview-frame {
    border-color: map-get($grey, 200);
    box-shadow: none;
    transition: background-color .15s linear;

    &:hover {
        background-color: map-get($grey, 50);
        box-shadow: none !important;
    }
}
