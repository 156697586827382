.promo {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .08);
    margin-bottom: 30px;

    a {
        display: block;
        text-decoration: none;
    }

    &:hover {
        .promo-content {
            background-color: map-get($color-secondary, 400);
            .promo-title {
                color: #fff;
            }

            .promo-subtitle {
                color: #fff;
            }
        }
    }
}

.promo-content {
    background-color: #fff;
    overflow: hidden;
    padding: 16px;
    position: relative;
    transition: background-color .15s linear;

    span {
        position: relative;
        z-index: 2;
    }
}

.promo-title {
    color: map-get($grey, 800);
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin: 0px 0px 8px 0px;
    transition: color .15s linear;
}

.promo-subtitle {
    color: map-get($grey, 500);
    display: block;
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    transition: color .15s linear;
}

.promo-title-over {
    bottom: 10px;
    color: #fff;
    left: 50%;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    transform: translateX(-50%);
    z-index: 99;
}

.promo-image {
    display: block;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    position: relative;
    overflow: hidden;
    transition: all .15s linear;

    img {
        transition: all .15s linear;
        width: 100%;
    }
}

.promo-image-inner {
    display: block;
    position: relative;
}
